<template>
  <ContentSection class="relative max-lg:-mb-96" spacing="none">
    <AppImage
      v-if="data.image"
      :asset="data.image"
      sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw 2xl:100vw 3xl:100vw"
      class="h-screen w-full object-cover lg:absolute lg:inset-0 lg:h-full lg:object-right-top"
    />

    <Container
      indent="tight"
      class="z-10 bg-transparent py-16 max-lg:top-32 max-lg:-translate-y-96 md:my-16 lg:h-auto"
    >
      <div
        class="max-w-xl bg-gray-10 px-6 py-10 max-lg:w-full lg:-translate-y-0 lg:bg-white lg:px-14 lg:py-16"
      >
        <AppImage
          v-if="data.logo"
          :asset="data.logo"
          class="mb-8 h-10 w-auto"
        />

        <h2 class="text-xl font-bold leading-8 lg:text-2xl lg:leading-9">
          {{ data.heading }}
        </h2>
        <div
          v-if="data.text"
          class="my-5 text-pretty text-base font-normal"
          v-html="data.text"
        />

        <AppButton
          v-if="data.link_text"
          :to="data.link"
          size="lg"
          class="mt-7 lg:mt-8"
        >
          {{ data.link_text }}
        </AppButton>

        <template v-if="data.remark">
          <p class="mt-14 text-pretty text-sm font-normal lg:text-base">
            {{ data.remark }}
          </p>

          <div
            v-if="general"
            class="mt-4 flex flex-col space-y-1 text-sm sm:flex-row sm:items-center sm:space-x-16 sm:space-y-0 sm:text-base"
          >
            <a
              v-if="general.phone"
              :href="`tel:${general.phone}`"
              class="inline-flex items-center"
            >
              <IconPhone class="h-3 sm:h-4" />
              <span class="mb-px ml-2 font-bold">{{ general.phone }}</span>
            </a>
            <a
              v-if="general.email"
              :href="`mailto:${general.email}`"
              class="inline-flex items-center"
            >
              <IconMail class="h-3 sm:h-4" />
              <span class="mb-px ml-2 font-bold">{{ general.email }}</span>
            </a>
          </div>
        </template>
      </div>
    </Container>
  </ContentSection>
</template>

<script setup lang="ts">
import type { AssetFragment } from '#gql'

defineProps<{
  data: {
    image: AssetFragment
    logo: AssetFragment
    heading: string
    text: string
    link: string
    link_text: string
    remark: string
  }
}>()

const cms = inject(CMS_GLOBALS)
const general = computed(() => cms?.value.general)
</script>
